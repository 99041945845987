type Params = {
  utm_medium?: string;
  utm_content?: string;
};

export function getSubscribeReferralURL(params: Params = {}) {
  return `${process.env.SUBSCRIBE_URL}?${new URLSearchParams({
    utm_source: 'website',
    coupon: 'JAN25',
    utm_campaign: 'january2025',
    ...params,
  }).toString()}`;
}
